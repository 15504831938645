<template>
  <div class="exam-wrap">
    <pageTitle pageTitle="快问快答"></pageTitle>
    <div>
      <exam-list scene="material"></exam-list>
    </div>
  </div>
</template>
<script>
  import PageTitle from '@/components/PageTitle';
  import ExamList from '../../PlatformModule/ExamList/index.vue';
  export default {
    name: 'exam',
    data() {
      return {};
    },
    components: {
      PageTitle,
      ExamList
    }
  };
</script>
<style lang="less" scoped>
  .exam-list {
    width: 100%;
    height: 100%;
    .layout--right--main();
    .padding-table-list();
    .min-height();
    /deep/.el-button .el-button--default .el-button--small .el-button--primary {
      margin-right: 20px;
    }
  }

  .exam-wrap {
    height: 100%;
    width: 100%;
    // /deep/.el-card__body{
    //   width: 100%;
    //   padding: 32px 24px;
    // }
    .head-operate {
      margin-bottom: 20px;
      .head-btn {
        display: inline-block;
      }
      /* .inputKey{
      float: right;
      height: 35px;
      width: 220px;
      /deep/.el-input__inner{
        border-radius: 18px;
      }
    } */
      .search-tag {
        float: right;
        width: 220px;
        /deep/ .el-input__icon {
          line-height: 36px;
        }
        /deep/.el-input__inner {
          border-radius: 20px;
          height: 36px;
          line-height: 36px;
          padding-right: 30px !important;
        }
        /deep/ .el-input__prefix {
          cursor: pointer;
        }
      }
    }
  }
</style>
